import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { URL } from "./utils/urls";

const Home = lazy(() => import("./pages/home"));
const Faq = lazy(() => import("./pages/faq"));
const Start = lazy(() => import("./pages/start"));
const Error = lazy(() => import("./pages/error"));
const Basic = lazy(() => import("./pages/start/BasicName"));
const Business = lazy(() => import("./pages/start/Business"));
// const Contact = lazy(() => import("./pages/start/Contact"));
const ContactUs = lazy(() => import("./pages/contact"));
const Password = lazy(() => import("./pages/start/Password"));
const Success = lazy(() => import("./pages/start/Success"));
const GetStarted = lazy(() => import("./pages/start/GetStarted"));
// const MposReward = lazy(() => import("./pages/start/MposReward"));
// const HomePage = lazy(() => import("./pages/start/HomePage"));
const Login = lazy(() => import("./pages/start/Login"));
const Enterprise = lazy(() => import("./pages/business/enterprise/Enterprise"));
const SuperAgent = lazy(() => import("./pages/business/superAgent/SuperAgent"));
const Personal = lazy(() => import("./pages/payment/personal/Personal"));
const SmallBusiness = lazy(() => import("./pages/payment/smallBusiness/SmallBusiness"));

function Main() {
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <Router>
        <Routes>
          <Route path={URL.Home} element={<Home />} />
          <Route path={URL.Faq} element={<Faq />} />
          <Route path={"signup"} element={<GetStarted />} />
          <Route path={"login"} element={<Login />} />
          <Route path={"contact-us"} element={<ContactUs />} />
          <Route path={"business/enterprise"} element={<Enterprise />} />
          <Route path={"business/super-agent"} element={<SuperAgent />} />
          <Route path={"payment/personal"} element={<Personal />} />
          <Route path={"payment/small-business"} element={<SmallBusiness />} />

          <Route path={URL.Start} element={<Start />}>
            <Route index element={<Basic />} />
            <Route path={"Business"} element={<Business />} />
            {/* <Route path={"Contact"} element={<Contact />} /> */}
            
            <Route path={"Auth"} element={<Password />} />
            <Route path={"Success"} element={<Success />} />
            <Route
              path={"*"}
              element={"Resource has been removed, renamed or doesn't exist"}
            />
          </Route>
          <Route path={"*"} element={<Error />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default Main;
